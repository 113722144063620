// Use process on development and window on production
const useWindowVariables =
  process.env.NODE_ENV === 'production' && typeof window !== 'undefined'

// required due to gatsby env magic
const staticProcessEnv = {
  GATSBY_CROSS_DOMAIN_BASE_URL: process.env.GATSBY_CROSS_DOMAIN_BASE_URL,
  GATSBY_DODO_DASHBOARD_BASE_URL: process.env.GATSBY_DODO_DASHBOARD_BASE_URL,
  GATSBY_GTM_CONTAINER: process.env.GATSBY_GTM_CONTAINER,
  GATSBY_SENTRY_DSN: process.env.GATSBY_SENTRY_DSN,
  COUNTRY: process.env.COUNTRY,
  APP_VERSION: process.env.APP_VERSION,
  NODE_ENV: process.env.NODE_ENV,

  GATSBY_LANGUAGES_LTR: process.env.GATSBY_LANGUAGES_LTR,
  GATSBY_TURNSTILE_SITE_KEY: process.env.GATSBY_TURNSTILE_SITE_KEY,
  GATSBY_ENABLE_CTA_BUTTON: process.env.GATSBY_ENABLE_CTA_BUTTON,
  GATSBY_DISABLE_COUNTRY_CODE_PICKER:
    process.env.GATSBY_DISABLE_COUNTRY_CODE_PICKER,
  GATSBY_DEFAULT_COUNTRY_CODE: process.env.GATSBY_DEFAULT_COUNTRY_CODE,
  GATSBY_OVERWRITE_SHOWN_NAME: process.env.GATSBY_OVERWRITE_SHOWN_NAME,
}

const getEnv = (name: keyof typeof staticProcessEnv) =>
  useWindowVariables ? window.env[name] : staticProcessEnv[name]

const crossDomainBaseUrl = getEnv('GATSBY_CROSS_DOMAIN_BASE_URL')

export const crossDomainConfig = {
  araraDashboardUrl: `${crossDomainBaseUrl}/dashboard/arara`,
  araraApiBaseUrl: `${crossDomainBaseUrl}/api/arara`,
  dodoApiBaseUrl: `${crossDomainBaseUrl}/api/dodo`,
}

export const backofficeOnlyConfig = {
  araraApiBaseUrl: '/api/arara',
  dodoApiBaseUrl: '/api/dodo',
  iamLoginBaseUrl: '/api/iam-login',
  dodoDashboardBaseURL: getEnv('GATSBY_DODO_DASHBOARD_BASE_URL'),
}

export const landingPageBuildOnlyConfig = {
  isCompilingLandingPage: process.env.COMPILING_FRONTOFFICE === 'true',
  landingPageId: process.env.LANDING_PAGE_ID_TO_COMPILE,
  countryId: process.env.LANDING_PAGE_COUNTRY_ID,
  domain: process.env.LANDING_PAGE_DOMAIN,
}

export const config = {
  gtmContainer: getEnv('GATSBY_GTM_CONTAINER'),
  sentryDSN: getEnv('GATSBY_SENTRY_DSN'),
  country: getEnv('COUNTRY'),
  version: getEnv('APP_VERSION'),
  env: getEnv('NODE_ENV'),
  languagesLtr: getEnv('GATSBY_LANGUAGES_LTR')?.split(',') ?? [],
  turnstileSiteKey: getEnv('GATSBY_TURNSTILE_SITE_KEY'),
  enableCTAButton: getEnv('GATSBY_ENABLE_CTA_BUTTON') === 'true',
  disableCountryCodePicker:
    getEnv('GATSBY_DISABLE_COUNTRY_CODE_PICKER') === 'true',
  defaultCountryCode: getEnv('GATSBY_DEFAULT_COUNTRY_CODE'),
  overwriteShownName: getEnv('GATSBY_OVERWRITE_SHOWN_NAME'),
}

export const allowedPublicEnvVariables = [
  'APP_VERSION',
  'DEPLOY_DATE_TIME',
  'NODE_ENV',
]

export const getPublicEnvironmentVariables = () =>
  Object.fromEntries(
    Object.entries(process.env).filter(
      ([key]) =>
        key.startsWith('GATSBY_') || allowedPublicEnvVariables.includes(key),
    ),
  )
